import { useState, useEffect, useRef } from 'react';

import { Detection } from '../constants';

const useIntersectionObserver = options => {
  const elRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useRef(
    Detection.IS_BROWSER
      ? new window.IntersectionObserver(
          changes => {
            for (const change of changes) {
              if (change.isIntersecting) {
                setIsIntersecting(true);
              } else {
                setIsIntersecting(false);
              }
            }
          },
          { ...options }
        )
      : null
  );
  useEffect(() => {
    if (Detection.IS_BROWSER) {
      if (observer.current && elRef.current) {
        observer.current.observe(elRef.current);
      } else
        console.error(`Image loader was loaded without 
      ${!observer.current ? 'Observer' : ''} ${!elRef.current ? 'ObserverElement' : ''}`);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (observer.current) observer.current.disconnect();
    };
  }, []);
  return [elRef, isIntersecting];
};

export default useIntersectionObserver;

const SELECTOR =
  'button:not([tabindex="-1"]), [href]:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])';
const DISABLED = 'tabindex-disabled';

let overlayCaller = [];

export const removeTabindexByElement = el => {
  if (el) {
    const focusable = el.querySelectorAll(SELECTOR);
    Array.from(focusable).forEach(el => {
      el.setAttribute('tabindex', -1);
      el.classList.add(DISABLED);
    });
  }
};

export const restoreTabindexByElement = el => {
  if (el) {
    const focusable = el.querySelectorAll(`.${DISABLED}`);
    Array.from(focusable).forEach(el => {
      el.classList.remove(DISABLED);
      el.setAttribute('tabindex', 0);
    });
  }
};

export const removeTabindex = (parentElementId, caller) => {
  const el = document.getElementById(parentElementId);
  if (el) {
    const focusable = el.querySelectorAll(SELECTOR);
    Array.from(focusable).forEach(el => {
      el.classList.add(DISABLED);
      el.setAttribute('tabindex', -1);
    });
    if (!overlayCaller.includes(caller)) overlayCaller = [...overlayCaller, caller];
  }
};

export const restoreTabIndex = caller => {
  if (overlayCaller.includes(caller)) overlayCaller = overlayCaller.filter(id => id !== caller);
  if (overlayCaller.length === 0) {
    const disabled = document.getElementsByClassName(DISABLED);
    Array.from(disabled).forEach(el => {
      el.classList.remove(DISABLED);
      el.setAttribute('tabindex', 0);
    });
  }
};

import { useEffect, useState } from 'react';
import { Detection } from '../constants';

const useDeferredLoading = (timeMs = 10) => {
  const [deferredLoaded, setDeferredLoaded] = useState(false);
  useEffect(() => {
    if (Detection.IS_BROWSER) {
      setTimeout(() => setDeferredLoaded(true), timeMs);
    }
  }, [timeMs]);
  return deferredLoaded;
};

export default useDeferredLoading;

import { css } from 'styled-components';

import { Width } from '../styles';

export const maxWidthContainer = (width = Width.XL, marginSmall = Width.MARGIN_SMALL, marginMedium = Width.MARGIN_MEDIUM) => css`
  margin: 0 auto;
  width: calc(100% - ${marginSmall * 2}px);

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    width: calc(100% - ${marginMedium * 2}px);
  }

  @media print, screen and (min-width: ${width + marginMedium * 2}px) {
    width: ${width}px;
  }
`;

import React from 'react';
import { MobileNavBar } from './Mobile';
import { DesktopNav } from './Desktop';
import { useBreakpoints, useDeferredLoading } from '../../../hooks';

const Navigation = () => {
  const breakpoint = useBreakpoints();
  const loaded = useDeferredLoading()
  return !loaded ? null : breakpoint === '' ? <MobileNavBar/> : <DesktopNav />
};

export default Navigation;

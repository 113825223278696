import { css } from 'styled-components';
import { Transition } from '../../styles';

const hover = (prop, base, hover, noFocus = false, timeMs = Transition.TIME_DEFAULT, ease = Transition.EASE_DEFAULT) => css`
  ${prop}: ${base};
  transition: ${prop} ${timeMs} ${ease};

  @media (any-hover: none) {
    &:hover {
      ${prop}: ${base};
    }

    &:active {
      ${prop}: ${hover};
    }
  }

  @media (any-hover: hover) {
    &:hover {
      ${prop}: ${hover};
    }
  }
`;

export default hover;

import React from 'react';
import styled from 'styled-components';
import { Link as BaseLink } from '../../Gui/Links';
import { Color, Fonts } from '../../../styles';

const Link = styled(BaseLink)`
  font-size: 2rem;
  ${Fonts.POPPINS};
  font-weight: 700;
  line-height: calc(65 / 20);
  letter-spacing: calc(-0.68em / 20);
  text-decoration: none;
  color: ${Color.BLACK};
`;

const NavLogo = ({ onClick, atTop, ...rest }) => {
  return (
    <Link url={'/'} onClick={onClick} {...rest}>
      Mike Cagney
    </Link>
  );
};

export default NavLogo;

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Body = ({ children, ...rest }) => (
  <Wrapper id={'body'} {...rest}>
    {children}
  </Wrapper>
);

export default Body;

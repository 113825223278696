import React from 'react';
import styled, { css } from 'styled-components';

export const hidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  pointer-events: none;
`;

const AriaHidden = styled.span`
  ${hidden}
`;

const VisuallyHidden = ({ hiddenText, ...rest }) => <AriaHidden {...rest}>&nbsp; {hiddenText}</AriaHidden>;

export default VisuallyHidden;

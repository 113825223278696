import * as colors from './Color';
import * as widths from './Width';
import * as layout from './Layout';
import * as transition from './Transition';

export const Color = colors;
export const Width = widths;
export const Layout = layout;
export const Transition = transition;

export { Fonts } from './Typography';

export { maxWidthContainer } from './max-width-container';
export { breakpoints, mediaQueries } from './MediaQueries';
export {
  globalH1Default,
  globalH2Default,
  globalH3Default,
  globalH4Default,
  globalH5Default,
  globalH6Default,
  headingStylesByBreakpoint,
} from './Typography/header';
export { hover, hoverMultiple, hoverChild } from './Hover';

export const WHITE = '#FFFFFF';
export const GREY_ONE = '#FAFBFD';
export const GREY_TWO = '#F6F6F6';
export const GREY_THREE = '#E6E6E6';
export const GREY_FOUR = '#D2D2D2';
export const GREY_FIVE = '#9EAAC7';
export const GREY_SIX = '#6A7187';
export const GREY_LIGHT = '#E4E5EB';
export const GREY_MEDIUM = '#4D4D4D';
export const GREY_DARK = '#4C5165';
export const PURPLE_MAIN = '#24007E';
export const PURPLE_FIGURE = '#42368E';
export const BLURPLE_LIGHT = '#F3F4F9';
export const BLURPLE_MOODY = '#190050';
export const BLACK = '#000000';
export const BLACK_SOUL = '#2D2D2D';
export const YELLOW_MELLOW = '#FEC82A';
export const DARK_BLURPLE = '#290D6D';
export const BLOCKY_GREY = '#797E92';
export const PURPLE_LIGHT = '#6C48C8';
export const ALMOST_BLACK = '#090909';
export const TEAL = '#28CEA8';
export const BLUE_BRIGHT = '#00ADD2';
export const RED = '#FF0000';
export const BLUE = '#0000FF';
export const NEARLY_BLURPLE = '#2A0B78';
export const FOCUS_BLUE = '#7B61FF';
export const TRINITY_BLACK = '#191C27';
export const MIDNIGHT = '#1F222E';
export const FIGURE_PAY_BLUE = '#5339D7';
export const FP_HIGHLIGHTER = '#DBF72C';
export const LIGHT_PURPLE = '#755AFF';

export const PRODUCT_600 = '#3923A9';

export const NEUTRAL_WHITE = '#FFFFFF';
export const NEUTRAL_700 = '#1A1B22';
export const NEUTRAL_750 = '#0F1014';
export const NEUTRAL_350 = '#9196AA';
export const NEUTRAL_300 = '#B0B5CA';

export const NOTICE = '#E01B25';

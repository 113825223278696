import React from 'react';
import styled from 'styled-components';

import { Color, Layout } from '../../../../styles';
import { Nav } from '../../../../data/global/nav';
import { Link as BaseLink } from '../../../Gui/Links';

const Wrapper = styled.div`
  position: fixed;
  z-index: ${Layout.Z_INDEX_MOBILE_MENU};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${Color.GREY_ONE};
  visibility: ${({ $showing }) => ($showing ? 'visible' : 'hidden')};
  transition: ${({ $showing }) => ($showing ? 'transform 400ms ease' : 'transform 0ms ease 10ms')};
  transform: translateY(${({ $showing }) => ($showing ? 0 : '-100%')});

  > div {
    padding-top: ${Layout.NAV_HEIGHT_MOBILE}px;
    display: flex;
    flex-direction: column;
  }
`;

const Link = styled(BaseLink)`
  color: ${Color.BLACK};
  text-decoration: none;
  padding: 21px 27px;
  border-top: 1px solid #6a7187;

  &:last-of-type {
    border-bottom: 1px solid #6a7187;
  }
`;

const MobileMenu = ({ showing, setMenuShowing }) => {
  return (
    <Wrapper $showing={showing}>
      <div>
        {Nav.map(({ title, mobileTitle, linkProps }, index) => {
          return (
            <Link key={`NavLink-${index}`} onClick={() => setMenuShowing(false)} {...linkProps}>
              {mobileTitle ? mobileTitle : title}
            </Link>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default MobileMenu;

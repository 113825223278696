import { css } from 'styled-components';

import * as Width from '../Width';

/* Header font sizes are relative to this base font size.
 *  The base font size is different for regular body text. */
const baseHeaderFontSize = 18;

// ~55px
export const globalH1Default = css`
  font-size: ${(baseHeaderFontSize * 3.052) / 10}rem;
  line-height: calc(48 / ${baseHeaderFontSize * 3.052});
  letter-spacing: calc(-0.5em / ${baseHeaderFontSize * 3.052});
  font-weight: 700;
`;

// ~44px
export const globalH2Default = css`
  font-size: ${(baseHeaderFontSize * 2.441) / 10}rem;
  line-height: calc(48 / ${baseHeaderFontSize * 2.441});
  letter-spacing: calc(-0.5em / ${baseHeaderFontSize * 2.441});
  font-weight: 700;
`;

// ~35px
export const globalH3Default = css`
  font-size: ${(baseHeaderFontSize * 1.953) / 10}rem;
  line-height: calc(40 / ${baseHeaderFontSize * 1.953});
  font-weight: 700;
`;

// ~28px
export const globalH4Default = css`
  font-size: ${(baseHeaderFontSize * 1.563) / 10}rem;
  line-height: calc(40 / ${baseHeaderFontSize * 1.563});
  font-weight: 700;
`;

// ~23px
export const globalH5Default = css`
  font-size: ${(baseHeaderFontSize * 1.25) / 10}rem;
  line-height: calc(32 / ${baseHeaderFontSize * 1.25});
  font-weight: 700;
`;

// 18px
export const globalH6Default = css`
  font-size: ${baseHeaderFontSize / 10}rem;
  line-height: calc(24 / ${baseHeaderFontSize});
  font-weight: 700;
`;

export const headingStylesByBreakpoint = (mobile, tablet, desktop) => css`
  ${mobile};

  ${tablet &&
  css`
    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      ${tablet};
    }
  `};

  ${desktop &&
  css`
    @media print, screen and (min-width: ${Width.BREAK_XL}px) {
      ${desktop}
    }
  `};
`;

import React from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const Container = styled.div`
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `};
  ${({ $height }) =>
    $height &&
    css`
      height: ${$height}px;
    `};

  /* add something for high contrast */
`;

const Svg = ({ children, title = '', width, height, isInformative = false, ...rest }) => {
  const string = title.replace(/ /g, '-');
  const ID = `${string}-${uuidv4()}`;
  if (isInformative && !title) console.error('Informative SVGs require title for accessibility');
  return (
    <Container
      as="svg"
      role={isInformative ? 'img' : null}
      aria-hidden={isInformative ? false : true}
      aria-labelledby={isInformative ? ID : null}
      $width={width}
      $height={height}
      {...rest}
    >
      {isInformative && <title id={ID}>{title}</title>}
      {children}
    </Container>
  );
};

export default Svg;

export const Nav = [
  {
    title: 'Feed',
    linkProps: {
      url: '/#feed',
    },
  },
  {
    title: 'Articles',
    linkProps: {
      url: '/articles',
    },
  },
  {
    title: 'Podcasts',
    linkProps: {
      url: '/podcasts',
    },
  },
  {
    title: 'Videos',
    linkProps: {
      url: '/videos',
    },
  },
  {
    title: 'About',
    linkProps: {
      url: '/about',
    },
  },
];

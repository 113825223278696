import React from 'react';
import UIContainer from '../UI';
import { QueryClientProvider, QueryClient } from 'react-query';
import { LockScreenContextProvider } from '../../../contexts/lock-screen-context';

const queryClient = new QueryClient();

const SiteContainer = ({ children, ...rest }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <LockScreenContextProvider>
        <UIContainer {...rest}>{children}</UIContainer>
      </LockScreenContextProvider>
    </QueryClientProvider>
  );
};

export default SiteContainer;

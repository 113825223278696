import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Color, Layout, maxWidthContainer } from '../../../../styles';
import { Detection } from '../../../../constants';
import { Nav } from '../../../../data/global/nav';
import { Link as BaseLink } from '../../../Gui/Links';
import NavLogo from '../NavLogo';

const Wrapper = styled.header`
  width: 100%;
  position: fixed;
  height: ${Layout.NAV_HEIGHT_DESKTOP / 10}rem;
  z-index: ${Layout.Z_INDEX_MOBILE_NAV_BAR};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background 300ms ease;
  background: ${({ $atTop }) => ($atTop ? 'transparent' : Color.WHITE)};

  > div:first-of-type {
    ${maxWidthContainer()};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Link = styled(BaseLink)`
  text-decoration: none;
  color: ${Color.TRINITY_BLACK};

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-of-type) {
    margin-right: 40px;
  }
`;

const NavLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DesktopNav = () => {
  const [atTop, setAtTop] = useState(true);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (window.scrollY <= 0) {
            setAtTop(true);
          } else {
            setAtTop(false);
          }
          ticking = false;
        });
      }
      ticking = true;
    };

    if (Detection.IS_BROWSER) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper id={'nav'} $atTop={atTop}>
      <div>
        <NavLogo atTop={atTop} />

        <NavLinksContainer $atTop={atTop}>
          {Nav.map(({ title, linkProps }, index) => {
            return (
              <Link key={`DesktopNavLink-${index}`} {...linkProps}>
                {title}
              </Link>
            );
          })}
        </NavLinksContainer>
      </div>
    </Wrapper>
  );
};

export default DesktopNav;

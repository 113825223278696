import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Color, Layout, maxWidthContainer } from '../../../../styles';
import { MobileMenu } from './';
import { useSetLockScreenState } from '../../../../contexts/lock-screen-context';
import { Detection } from '../../../../constants';
import NavLogo from '../NavLogo';

const Wrapper = styled.header`
  width: 100%;
  position: fixed;
  height: ${Layout.NAV_HEIGHT_MOBILE / 10}rem;
  z-index: ${Layout.Z_INDEX_MOBILE_NAV_BAR};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: transform 300ms ease;
  transform: translateY(${({ $navBarShowing, $atTop }) => ($navBarShowing || $atTop ? 0 : '-100%')});
  background: ${({ $atTop }) => ($atTop ? 'transparent' : Color.WHITE)};

  > div:first-of-type {
    ${maxWidthContainer()};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const NavButton = styled.div`
  position: relative;
  width: 24px;
  height: 14px;

  > div {
    position: absolute;
    height: 2px;
    width: 24px;
    background-color: ${Color.BLACK};
    border-radius: 100px;

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 6px;
    }

    &:last-of-type {
      bottom: 0;
      width: 16px;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

const MobileNavBar = () => {
  const [menuShowing, setMenuShowing] = useState(false);
  const [navBarShowing, setNavBarShowing] = useState(true);
  const [atTop, setAtTop] = useState(true);
  const lockScreenPosition = useSetLockScreenState();

  useEffect(() => {
    if (menuShowing) {
      lockScreenPosition(true);
    } else {
      lockScreenPosition(false);
    }
  }, [menuShowing, lockScreenPosition]);

  useEffect(() => {
    let scrollPos = 0;
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (window.scrollY > scrollPos) {
            setNavBarShowing(true);
          } else {
            setNavBarShowing(false);
          }

          if (window.scrollY <= 0) {
            setAtTop(true);
          } else {
            setAtTop(false);
          }
          scrollPos = window.scrollY;
          ticking = false;
        });
      }
      ticking = true;
    };

    if (Detection.IS_BROWSER) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <MobileMenu showing={menuShowing} setMenuShowing={setMenuShowing} />
      <Wrapper id={'nav'} $navBarShowing={navBarShowing} $atTop={atTop}>
        <div>
          <NavLogo
            onClick={() => {
              if (menuShowing) {
                setMenuShowing(!menuShowing);
              }
            }}
            atTop={atTop}
          />

          <NavButton onClick={() => setMenuShowing(!menuShowing)} $atTop={atTop}>
            <div />
            <div />
            <div />
          </NavButton>
        </div>
      </Wrapper>
    </>
  );
};

export default MobileNavBar;

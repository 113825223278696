import React from 'react';
import styled from 'styled-components';
import { Location } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import { VisuallyHidden } from '../../Global/Aria';

const Button = styled.button``;

const StyledLink = styled.a``;

const Link = ({ url, external, label, sameTab, visuallyHidden, children, ...rest }) => {
  return url ? (
    <>
      {external || url.includes('//') || url.includes(':') ? (
        <StyledLink href={url} aria-label={label} rel="noopener noreferrer" target={sameTab ? null : `_blank`} {...rest}>
          {children}
          <VisuallyHidden hiddenText={visuallyHidden || 'Opens a new window.'} />
        </StyledLink>
      ) : url.startsWith('#') ? (
        <Location>
          {({ location: { pathname } }) => (
            <GatsbyLink aria-label={label} to={`${pathname}${url}`} {...rest}>
              {children}
              {visuallyHidden && <VisuallyHidden hiddenText={visuallyHidden} />}
            </GatsbyLink>
          )}
        </Location>
      ) : (
        <GatsbyLink aria-label={label} to={!url.includes('#') && url.substring(url.length - 1) !== '/' ? `${url}/` : url} {...rest}>
          {children}
          {visuallyHidden && <VisuallyHidden hiddenText={visuallyHidden} />}
        </GatsbyLink>
      )}
    </>
  ) : (
    <Button type="button" aria-label={label} {...rest}>
      {children}
      {visuallyHidden && <VisuallyHidden hiddenText={visuallyHidden} />}
    </Button>
  );
};

export default Link;

const ENV_DEV = 'development';
const ENV_TEST = 'test';
export const IS_DEV = [ENV_DEV, ENV_TEST].includes(process.env.GATSBY_ENV);
export const IS_TEST = process.env.GATSBY_ENV === ENV_TEST;
export const IS_BROWSER = typeof window !== 'undefined';
export const IS_LOCALHOST = IS_BROWSER && window.location.hostname === 'localhost';
export const HAS_LOCAL_STORAGE =
  IS_BROWSER &&
  (() => {
    const mod = 'arbitrary_value';
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  })();

import React from 'react';

import GlobalStyles from '../../../global-styles';
import Body from '../Body';
import Navigation from '../Nav/Navigation';

const UIContainer = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Navigation />
      <Body>{children}</Body>
    </>
  );
};

export default UIContainer;

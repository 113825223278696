import { BODY_ID, Detection } from '../constants';

let cachedY = 0;
let bodyRef;
let overlayCaller = [];

const NAV_OVERLAY_ID = 'nav';

export const initFullscreenOverlay = caller => {
  if (!bodyRef) bodyRef = document.getElementById(BODY_ID);
  cachedY = window.scrollY || window.pageYOffset;
  toggleLock(true);
  if (bodyRef) bodyRef.style.transform = `translateY(-${cachedY}px)`;
  if (!overlayCaller.includes(caller)) overlayCaller = [...overlayCaller, caller];
};

export const closeFullscreenOverlay = (caller, pageToTop = false) => {
  if (overlayCaller.includes(caller)) {
    overlayCaller = overlayCaller.filter(id => id !== caller);
  }
  if (overlayCaller.length === 0 || caller === NAV_OVERLAY_ID) {
    if (!bodyRef) bodyRef = document.getElementById(BODY_ID);
    toggleLock(false);
    if (bodyRef) bodyRef.style.transform = 'unset';
    if (pageToTop) {
      cachedY = 0;
    } else if (cachedY !== 0) {
      setTimeout(() => {
        window.scrollTo(0, cachedY);
        cachedY = 0;
      }, 1);
    }
    overlayCaller = [];
  }
};

export const toggleLock = lock => {
  if (Detection.IS_BROWSER) {
    const { body, documentElement } = document;
    body.style.overflowY = lock ? 'hidden' : '';
    body.style.height = lock ? '100%' : '';
    body.style.width = lock ? '100%' : '';
    body.style.position = lock ? 'fixed' : '';
    documentElement.style.overflowY = lock ? 'hidden' : '';
    documentElement.style.height = lock ? '100%' : '';
    documentElement.style.position = lock ? 'fixed' : '';
    documentElement.style.width = lock ? '100%' : '';
  }
};
